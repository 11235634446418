@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./assets/scss/general.scss');

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
}
